<template>
  <div class="home">
    <component :is="component" :shop="shop" />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, PropType, ref, watch } from 'vue'
import { useStore } from '@/use/useStore'
import { SHOP_STORE } from '@/store/constants'
import HomeBabyboerse from '@/views/shop/babyboerse/Home.vue'
import HomeMain from '@/views/shop/main/Home.vue'
import Loading from '@/components/Loading.vue'
import { Shop } from '@/store/modules/shop/interfaces'
import { useMeta } from 'vue-meta'

export default defineComponent({
  name: 'Home',
  props: {
    shop: {
      type: Object as PropType<Shop>
    }
  },
  components: { Loading, HomeMain, HomeBabyboerse },
  setup (props) {
    const store = useStore()

    const component = ref('')

    function switchComponent () {
      if (props.shop && props.shop.category === 'main') {
        component.value = 'HomeMain'
        return
      }
      if (props.shop && props.shop.category === 'babyboerse') {
        component.value = 'HomeBabyboerse'
        return
      }
      component.value = 'Loading'
    }

    switchComponent()

    watch(props, () => {
      switchComponent()
    })

    useMeta(computed(() => ({
      title: `Home | ${props.shop?.name.toUpperCase() || 'SHOP-VOR-ORT.DE'}`,
      meta: [
        { name: 'description', content: 'Kleider- und Babybörse in Windberge' }
      ]
    })))

    return {
      component
    }
  }
})
</script>
