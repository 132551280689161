import { SignUp } from '@/store/modules/auth/interfaces'
import { AuthApiResponse } from '@/services/api/auth/interfaces'
import { userApi } from '@/services/api/api'

/**
 * register new user
 * @param signUpData
 */
function authSignUp (signUpData: SignUp): Promise<AuthApiResponse> {
  return userApi.post('/', signUpData)
    .then((response) => {
      return Promise.resolve(response.data)
    })
    .catch((err) => Promise.reject(err.response))
}

/**
 * check if user or e-mail exists
 * @param username
 */
function checkUsername (username: string): Promise<boolean> {
  const encoded = encodeURIComponent(username)
  return userApi.get(`/checkUsername/${encoded}`)
    .then((response) => {
      if (response && response.data && response.data.success) {
        return Promise.resolve(true)
      }
      return Promise.resolve(false)
    })
    .catch((err) => Promise.reject(err.response))
}

/**
 * check if e-mail exists
 * @param email
 */
function checkEmail (email: string): Promise<boolean> {
  const encoded = encodeURIComponent(email)
  return userApi.get(`/checkEmail/${encoded}`)
    .then((response) => {
      if (response && response.data && response.data.success) {
        return Promise.resolve(true)
      }
      return Promise.resolve(false)
    })
    .catch((err) => Promise.reject(err.response))
}

/**
 * verify e-mail with token
 * @param token
 */
function verifyEmail (token: string): Promise<boolean> {
  return userApi.get(`/verify/${token}`)
    .then((response) => {
      if (response && response.data && response.data.success) {
        return Promise.resolve(true)
      }
      return Promise.resolve(false)
    })
    .catch((err) => Promise.reject(err.response))
}

/**
 * send email with token to change pwd
 * @param userIdent
 */
async function getNewPwdToken (userIdent: string) {
  try {
    await userApi.post('/getNewPwdToken', { userIdent: userIdent })
  } catch (e) {
    console.log(' ')
  }
}

/**
 * change pwd for user
 * @param pwd
 * @param token
 */
async function changePwd (pwd: string, token: string): Promise<AuthApiResponse | undefined> {
  try {
    return await userApi.post('/changePwd', { pwd, token })
  } catch (e) {
    return e
  }
}

export {
  authSignUp,
  checkUsername,
  checkEmail,
  verifyEmail,
  getNewPwdToken,
  changePwd
}
