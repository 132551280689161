import { ActionTree } from 'vuex'
import { IRootState } from '@/store/interfaces'
import {
  ShopActionsTypes,
  ShopStateTypes, Shop
} from './interfaces'
import { SHOP_STORE } from '@/store/constants'
import { getCurrentShop } from '@/services/api/shop/api'
import { ApiResponse } from '@/services/api/api'
import { setLocalStorage } from '@/services/utils/localStorage'

export const actions: ActionTree<ShopStateTypes, IRootState> &
  ShopActionsTypes = {
    async [SHOP_STORE.ACTIONS.GET_CURRENT_SHOP] ({ commit }): Promise<Shop> {
      try {
        const response: ApiResponse = await getCurrentShop()
        if (!response.data || !response.data.shop) throw new Error('no shop')
        const shop: Shop = {
          id: response.data.shop.id || '',
          siteId: response.data.shop.siteId || '',
          name: response.data.shop.name || '',
          desc: response.data.shop.desc || '',
          category: response.data.shop.category || '',
          status: response.data.shop.status || 0
        }
        if (shop) commit(SHOP_STORE.MUTATIONS.SET_SHOP_DATA, shop)
        setLocalStorage('shop', shop)
        return shop
      } catch (e) {
        return Promise.reject(e)
      }
    }
  }
