import { createApp } from 'vue'
import App from './App.vue'
import { createMetaManager } from 'vue-meta'
import router from './router'
import { store } from './store'
import { BootstrapIconsPlugin } from 'bootstrap-icons-vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'
import './assets/scss/main.scss'

createApp(App).use(createMetaManager()).use(store).use(router).use(BootstrapIconsPlugin).mount('#app')
