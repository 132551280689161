<template>
  <div class="shop-event-list">
    <div class="card shadow">
      <h3 class="card-header">
        Termine
      </h3>
      <div class="card-body">
        <div class="row mb-2" v-for="(event) in filteredEvents" :key="event.id">
          <div class="col-12 text-start">
            <ShopEventListItem :event="event" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, PropType, ref, watch } from 'vue'
import moment from 'moment'
import ShopEventListItem from '@/components/shop/event/EventListItem.vue'
import { Event } from '@/store/modules/event/interfaces'

export default defineComponent({
  name: 'ShopEventList',
  components: { ShopEventListItem },
  props: {
    events: {
      type: Object as PropType<Event[]>
    },
    maxRows: Number
  },
  setup (props) {
    function getDate (date: Date) {
      return moment(date).format('LL')
    }
    function getTime (date: Date) {
      return moment(date).format('LT')
    }

    const filteredEvents = ref()
    function filterEvents () {
      if (props.maxRows && props.events) {
        const filtered: Array<any> = []
        for (let c = 0; c < props.events.length && c < props.maxRows; c++) {
          filtered[c] = props.events[c]
        }
        filteredEvents.value = filtered
      } else {
        filteredEvents.value = props.events
      }
    }

    watch(props, () => {
      filterEvents()
    })

    return {
      getDate,
      getTime,
      filteredEvents
    }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
