import { MutationTree } from 'vuex'
import { AuthMutationsTypes, AuthStateTypes, CurrentUser } from './interfaces'
import { AUTH_STORE } from '@/store/constants'

export const mutations: MutationTree<AuthStateTypes> &
  AuthMutationsTypes = {
    [AUTH_STORE.MUTATIONS.SET_USER_DATA] (state: AuthStateTypes, payload: CurrentUser) {
      if (payload) state.user = payload
    },
    [AUTH_STORE.MUTATIONS.RESET_USER] (state: AuthStateTypes) {
      state.user = undefined
    }
  }
