import { AUTH_STORE } from '@/store/constants'
import { GetterTree } from 'vuex'
import { AuthGettersTypes, AuthStateTypes, CurrentUser } from '@/store/modules/auth/interfaces'
import {
  IRootState
} from './../../interfaces'

export const getters: GetterTree<AuthStateTypes, IRootState> &
  AuthGettersTypes = {
    [AUTH_STORE.GETTERS.GET_USER_DATA]: (state: AuthStateTypes): CurrentUser | undefined => {
      return state.user ? state.user as CurrentUser : undefined
    },
    [AUTH_STORE.GETTERS.IS_LOGGED_IN]: (state: AuthStateTypes) => {
      return !!state.user?.token
    }
  }
