import { SHOP_STORE } from '@/store/constants'
import { GetterTree } from 'vuex'
import {
  IRootState
} from './../../interfaces'
import { Shop, ShopGettersTypes, ShopStateTypes } from '@/store/modules/shop/interfaces'

export const getters: GetterTree<ShopStateTypes, IRootState> &
  ShopGettersTypes = {
    [SHOP_STORE.GETTERS.GET_SHOP_DATA]: (state: ShopStateTypes): Shop | undefined => {
      return state.shop ? state.shop as Shop : undefined
    }
  }
