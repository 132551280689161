<template>
  <div class="container p-0" style="max-width: 2000px">
    <div id="home-carousel" class="carousel slide" data-bs-ride="carousel" style="max-height: 20rem; min-height: 10rem">
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img src="/img/babyboerse001.jpg" class="d-block w-100" alt="Babyboerse">
        </div>
        <div class="carousel-item">
          <img src="/img/babyboerse002.jpg" class="d-block w-100" alt="Babyboerse">
        </div>
        <div class="carousel-item">
          <img src="/img/babyboerse003.jpg" class="d-block w-100" alt="Babyboerse">
        </div>
        <div class="carousel-item">
          <img src="/img/babyboerse004.jpg" class="d-block w-100" alt="Babyboerse">
        </div>
        <div class="carousel-item">
          <img src="/img/babyboerse005.jpg" class="d-block w-100" alt="Babyboerse">
        </div>
        <div class="carousel-item">
          <img src="/img/babyboerse006.jpg" class="d-block w-100" alt="Babyboerse">
        </div>
        <div class="carousel-item">
          <img src="/img/babyboerse007.jpg" class="d-block w-100" alt="Babyboerse">
        </div>
        <div class="carousel-item">
          <img src="/img/babyboerse008.jpg" class="d-block w-100" alt="Babyboerse">
        </div>
        <div class="carousel-item">
          <img src="/img/babyboerse009.jpg" class="d-block w-100" alt="Babyboerse">
        </div>
        <div class="carousel-item">
          <img src="/img/babyboerse010.jpg" class="d-block w-100" alt="Babyboerse">
        </div>
        <div class="carousel-item">
          <img src="/img/babyboerse011.jpg" class="d-block w-100" alt="Babyboerse">
        </div>
        <div class="carousel-item">
          <img src="/img/babyboerse012.jpg" class="d-block w-100" alt="Babyboerse">
        </div>
        <div class="carousel-item">
          <img src="/img/babyboerse013.jpg" class="d-block w-100" alt="Babyboerse">
        </div>
        <div class="carousel-item">
          <img src="/img/babyboerse014.jpg" class="d-block w-100" alt="Babyboerse">
        </div>
        <div class="carousel-item">
          <img src="/img/babyboerse015.jpg" class="d-block w-100" alt="Babyboerse">
        </div>
        <div class="carousel-item">
          <img src="/img/babyboerse016.jpg" class="d-block w-100" alt="Babyboerse">
        </div>
        <div class="bg-dark bg-opacity-50" style="position: absolute; top: 1rem; right: 5%; left: 5%; padding: 0.5rem">
          <h1 class="text-white my-0">{{ shop.name }}</h1>
          <h6 class="text-white mb-0">Kinderkleidung, Babyzubehör, Spielzeug und vieles mehr</h6>
        </div>
      </div>
    </div>
  </div>
  <div class="home-babyboerse container-lg">
    <ShopEventList v-show="events.length > 0" :events="events" :max-rows="2" />
    <div class="row row-eq-height gx-3 mt-3">
      <div class="col-lg-4 mb-3">
        <div class="card shadow h-100">
          <h3 class="card-header">
            Wann?
          </h3>
          <div class="card-body">
            <p class="fs-6 text-danger text-uppercase fw-bolder">Nächste Babyböerse {{ events[0] ? getDiffNow(events[0].start) : "" }}</p>
            <p class="fs-6 fw-bolder"><b-icon-calendar-check /><br>{{ events[0] ? getDate(events[0].start) : "Termin offen" }}</p>
            <p class="fs-6 fw-bolder"><b-icon-clock /><br>{{ events[0] ? `${getTime(events[0].start)} Uhr bis ${getTime(events[0].end)} Uhr` : "Termin offen" }}</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 mb-3">
        <div class="card shadow h-100">
          <h3 class="card-header">
            Wo?
          </h3>
          <div class="card-body">
            <p class="fs-5 fw-bolder">Bürgerhaus Windberge</p>
            <p>39517 Windberge, Friedhofsweg</p>
          </div>
          <div class="card-footer">
            <a class="btn btn-primary shadow"
               href="https://www.google.de/maps/place/Friedhofsweg,+39517+Windberge/@52.5222553,11.7141132,993m/data=!3m2!1e3!4b1!4m13!1m7!3m6!1s0x47af3e3b0fca1657:0xe6a9b6da5709e762!2sFriedhofsweg,+39517+Windberge!3b1!8m2!3d52.5222553!4d11.7163019!3m4!1s0x47af3e3b0fca1657:0xe6a9b6da5709e762!8m2!3d52.5222553!4d11.7163019"
               target="_blank"><b-icon-geo-alt-fill /> zur Karte</a>
          </div>
        </div>
      </div>
      <div class="col-lg-4 mb-3">
        <div class="card shadow h-100">
          <h3 class="card-header">
            Was wird verkauft?
          </h3>
          <div class="card-body">
            <ul class="text-start">
              <li>Kinderbekleidung von Gr. 50 bis 188</li>
              <li>Kinderschuhe, Kinderstiefel, Mützen, Jacken, ...</li>
              <li>Spielzeug, Bücher, Brettspiele, Spieleconsolen, Spiele für Spieleconsolen ...</li>
              <li>Kinderwagen, Kindersitze, Laufräder, Fahrräder ...</li>
              <li>„Zubehör“ rund ums Kind und Kleinkind</li>
              <li>u. v. m.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="row row-eq-height gx-3">
      <div class="col-sm-12 col-lg-6 mb-3">
        <div class="card shadow h-100">
          <h3 class="card-header">
            Verkäufer
          </h3>
          <div class="card-body">
            <h4 class="text-danger text-uppercase fw-bolder">Du möchtest etwas verkaufen?</h4>
            <p class="fw-bolder">Wer als Verkäufer an unserer Börse teilnehmen möchte, registriert sich auf unserer Seite und hinterlegt seine Adressdaten. Ganz einfach!</p>
              <p><strong>
                Die Anmeldefrist endet am
                {{ events[0] ? getDiff(events[0].start, -13, 'dddd, D. MMM YYYY') : '' }}.
              </strong></p>
            <p><strong>
              Die Vergabe der Verkäufernummern erfolgt am
              {{ events[0] ? getDiff(events[0].start, -12, 'dddd, D. MMM YYYY') : '' }}
              per Auslosung.
            </strong></p>
            <p>Alle ausgelosten Verkäufer werden von uns per E-Mail über die weiteren Details informiert.</p>
          </div>
          <div class="card-footer">
            <router-link class="btn btn-primary shadow" :to="{ name: 'Verkaeufer' }">Informationen & Registrierung für Verkäufer</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref, watch } from 'vue'
import ShopEventList from '@/components/shop/event/EventList.vue'
import { useStore } from '@/use/useStore'
import { SHOP_STORE } from '@/store/constants'
import { ApiResponse } from '@/services/api/api'
import { getAll } from '@/services/api/event/api'
import { Event } from '@/store/modules/event/interfaces'
import moment from 'moment'
import { Shop } from '@/store/modules/shop/interfaces'
import { useMeta } from 'vue-meta'

export default defineComponent({
  name: 'HomeBabyboerse',
  components: { ShopEventList },
  props: {
    shop: {
      type: Object as PropType<Shop>
    }
  },
  setup (props) {
    function getDate (date: Date) {
      return moment(date).format('dddd, D. MMM YYYY')
    }
    function getTime (date: Date) {
      return moment(date).format('LT')
    }

    function getDiff (date: Date, days: number, format: string) {
      return moment(date).add(days, 'd').format(format)
    }

    function getDiffNow (start: Date) {
      const now = new Date()
      if (moment(start) < moment(now)) {
        return 'aktuell geöffnet'
      }
      return moment(start).fromNow()
    }

    const store = useStore()
    const shop = computed(() => store.getters[SHOP_STORE.GETTERS.GET_SHOP_DATA])
    const events = ref<Event[]>([])
    async function getEvents () {
      try {
        const date: Date = new Date()
        const resp: ApiResponse = await getAll(date, 'babyboerse', shop.value ? shop.value.id : undefined)
        if (resp.data && resp.data.events) events.value = resp.data.events
      } catch (e) {
        console.log(e)
      }
    }
    watch(shop, () => {
      getEvents()
    })

    getEvents()

    useMeta(computed(() => ({
      title: `Home | ${props.shop?.name.toUpperCase() || 'SHOP-VOR-ORT.DE'}`,
      meta: [
        {
          name: 'description',
          content:
            'Seit mehr als 10 Jahren findet zweimal im Jahr (Frühjahr und Herbst) die Kinderkleiderbörse, ' +
            'Spielzeugbörse und Babybörse in Windberge (Stendal) statt.' +
            'Die aktuellen Termine und wie die Anmeldung als Verkäufer funktioniert findest Du hier auf einen Blick.'
        }
      ]
    })))

    return {
      events,
      getDate,
      getTime,
      getDiff,
      getDiffNow
    }
  }
})
</script>
