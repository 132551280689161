<template>
  <div class="card">
    <router-link :to="{ name: 'EventShow',  params: { id: event.path }}" class="btn m-0 p-0"
                 title="Veranstaltung anzeigen">
      <h4 class="card-header">
        {{ event.name }}
      </h4>
      <div class="card-body">
        <div class="row row-eq-height align-items-center">
          <div class="col-12 col-md-4 mb-1 fw-bold">{{ event.desc }}</div>
          <div class="col-5 col-md-3 text-center">
            <strong>Beginn</strong><br>
            {{ getDate(event.start) }}<br>
            {{ getTime(event.start) }} Uhr
          </div>
          <div class="col-5 col-md-3 text-center">
            <strong>Ende</strong><br>
            {{ getDate(event.end) }}<br>
            {{ getTime(event.end) }} Uhr
          </div>
          <div class="col-2 align-items-end text-end">
            <span class="text-primary fs-3"><b-icon-forward-fill/></span>
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import moment from 'moment'
import { Event } from '@/store/modules/event/interfaces'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'ShopEventListItem',
  props: {
    event: {
      type: Object as PropType<Event>
    }
  },
  setup (props) {
    const router = useRouter()

    function getDate (date: Date) {
      return moment(date).format('LL')
    }
    function getTime (date: Date) {
      return moment(date).format('LT')
    }

    function showEvent (id: string) {
      router.push({ name: 'EventShow', params: { id: id } })
    }

    return {
      getDate,
      getTime,
      showEvent
    }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
