import axios from 'axios'
import { store } from '@/store'
import { checkTokenFromApiError } from '@/services/api/auth/api'
import { AUTH_STORE } from '@/store/constants'

export interface ApiResponse {
  success: boolean;
  err: string | null;
  msg: string;
  errors: [];
  data?: any
}

export interface ApiError {
  statusCode: number;
  statusText: string;
  dataErr?: string;
  dataMsg?: string;
}

const apiHost = process.env.VUE_APP_API_HOST || 'http://localhost:5000'

const jkasseApi = axios.create({
  baseURL: `${apiHost}/api`
})

const userApi = axios.create({
  baseURL: `${apiHost}/api/user`
})

const addressApi = axios.create({
  baseURL: `${apiHost}/api/address`
})

const personApi = axios.create({
  baseURL: `${apiHost}/api/person`
})

const authApi = axios.create({
  baseURL: `${apiHost}/api/auth`
})

function getResponseData (res: any): any {
  // test for new token
  if (res && res.data && res.data.user && res.data.user.token) {
    store.dispatch(AUTH_STORE.ACTIONS.REFRESH_TOKEN, res.data.user.token as string)
  }
  return res.data
}

const handleApiErrors = (errResponse: any): ApiError => {
  let apiError: ApiError
  if (errResponse.response.status) {
    apiError = {
      statusCode: errResponse.response.status,
      statusText: errResponse.response.statusText
    }
    if (errResponse.response.data.err) {
      apiError.dataErr = errResponse.response.data.err
    }
    if (errResponse.response.data.msg) {
      apiError.dataMsg = errResponse.response.data.msg
    } else if (errResponse.response.data.message) {
      apiError.dataMsg = errResponse.response.data.message
    }
  } else {
    apiError = {
      statusCode: 999,
      statusText: JSON.stringify(errResponse.response)
    }
  }
  // check and verify if token-error
  checkTokenFromApiError(apiError)

  return apiError
}

export {
  addressApi,
  authApi,
  jkasseApi,
  personApi,
  userApi,
  getResponseData,
  handleApiErrors
}
