import { ActionTree } from 'vuex'
import { IRootState } from '@/store/interfaces'
import {
  AuthActionsTypes,
  AuthStateTypes, CurrentUser, Login, SignUp
} from './interfaces'
import { AUTH_STORE } from '@/store/constants'
import { AuthApiResponse } from '@/services/api/auth/interfaces'
import { authLogin } from '@/services/api/auth/api'
import { authSignUp } from '@/services/api/user/api'
import { setLocalStorage } from '@/services/utils/localStorage'
import { state } from '@/store/modules/auth/state'

export const actions: ActionTree<AuthStateTypes, IRootState> &
  AuthActionsTypes = {
    async [AUTH_STORE.ACTIONS.LOGIN] ({ commit }, payload: Login): Promise<CurrentUser> {
      try {
        const response: AuthApiResponse = await authLogin(payload)
        if (!response.user) throw new Error('no user')
        const currentUser: CurrentUser = {
          username: response.user.username || '',
          email: response.user.email || '',
          token: response.user.token || '',
          roles: ['admin']
        }
        // store user in local storage
        if (currentUser) setLocalStorage('currentUser', currentUser)
        if (currentUser) commit(AUTH_STORE.MUTATIONS.SET_USER_DATA, currentUser)
        return currentUser
      } catch (e) {
        return Promise.reject(e)
      }
    },
    [AUTH_STORE.ACTIONS.LOGOUT] ({ commit }) {
      localStorage.removeItem('currentUser')
      commit(AUTH_STORE.MUTATIONS.RESET_USER)
    },
    [AUTH_STORE.ACTIONS.REFRESH_TOKEN] ({ commit }, payload: string): CurrentUser | void {
      const currentUser = state.user
      if (currentUser) {
        currentUser.token = payload
        if (currentUser) setLocalStorage('currentUser', currentUser)
        if (currentUser) commit(AUTH_STORE.MUTATIONS.SET_USER_DATA, currentUser)
        return currentUser
      }
      return undefined
    },
    async [AUTH_STORE.ACTIONS.SIGN_UP] ({ commit }, payload: SignUp): Promise<CurrentUser> {
      try {
        const response: AuthApiResponse = await authSignUp(payload)
        // if (!response.user) throw new Error('no user')
        const currentUser: CurrentUser = {
          username: payload.username || '',
          email: payload.email || '',
          token: '',
          roles: ['guest']
        }
        // store user in local storage
        if (currentUser) setLocalStorage('currentUser', currentUser)
        if (currentUser) commit(AUTH_STORE.MUTATIONS.SET_USER_DATA, currentUser)
        return Promise.resolve(currentUser)
      } catch (e) {
        return Promise.reject(e)
      }
    }
  }
