<template>
  <div id="mainnav">
    <nav class="navbar navbar-expand-md navbar-dark bg-dark" aria-label="MainNav">
      <div class="container-lg container-fluid">
        <router-link class="navbar-brand" :to="{ name: 'Home' }">Babybörse Windberge</router-link>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarMainNav" aria-controls="navbarMainNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarMainNav">
          <ul class="navbar-nav me-auto mb-2 mb-md-0">
            <li class="nav-item">
              <router-link class="nav-link" aria-current="page" :to="{ name: 'Home' }">Home</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" aria-current="page" :to="{ name: 'Besucher' }">Besucher / Käufer</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" aria-current="page" :to="{ name: 'Verkaeufer' }">Verkäufer</router-link>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="dropdown04" data-bs-toggle="dropdown" aria-expanded="false">Über uns</a>
              <ul class="dropdown-menu" aria-labelledby="dropdown04">
                <li><router-link class="dropdown-item" :to="{ name: 'Impressum' }">Impressum</router-link></li>
                <li><router-link class="dropdown-item" :to="{ name: 'Datenschutz' }">Datenschutz</router-link></li>
              </ul>
            </li>
          </ul>
          <ul class="navbar-nav">
            <li class="nav-item dropdown" v-if="isLoggedIn">
              <a class="nav-link dropdown-toggle" href="#" id="userdropdown" data-bs-toggle="dropdown"
                 aria-expanded="false">Hallo, {{ currentUser.username }}</a>
              <ul class="dropdown-menu" ria-labelledby="userdropdown">
                <router-link class="dropdown-item" :to="{ name: 'UserProfile' }">Mein Profil</router-link>
                <router-link class="dropdown-item" :to="{ name: 'EventUserList' }">Meine Verkaufsdaten</router-link>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" @click="logoutAction"><b-icon-box-arrow-right /> Logout</a>
              </ul>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/login" v-if="!isLoggedIn"><b-icon-box-arrow-in-right />  Login</router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { useStore } from '@/use/useStore'
import { AUTH_STORE } from '@/store/constants'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'MainNav',
  setup () {
    const store = useStore()
    const router = useRouter()
    const isLoggedIn = computed(() => store.getters[AUTH_STORE.GETTERS.IS_LOGGED_IN])
    const currentUser = computed(() => store.getters[AUTH_STORE.GETTERS.GET_USER_DATA])

    function logoutAction () {
      store.dispatch(AUTH_STORE.ACTIONS.LOGOUT)
      router.push({ name: 'Home' })
    }

    return {
      isLoggedIn,
      logoutAction,
      currentUser
    }
  }
})
</script>

<style scoped>

</style>
