<template>
  <div class="home">
    <ShopEventList :events="events" />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import ShopEventList from '@/components/shop/event/EventList.vue'
import { useStore } from '@/use/useStore'
import { SHOP_STORE } from '@/store/constants'
import { ApiResponse } from '@/services/api/api'
import { getAll } from '@/services/api/event/api'

export default defineComponent({
  name: 'HomeMain',
  components: { ShopEventList },
  setup () {
    const store = useStore()
    const shop = computed(() => store.getters[SHOP_STORE.GETTERS.GET_SHOP_DATA])
    const events = ref([])
    async function getEvents () {
      try {
        const date: Date = new Date()
        const resp: ApiResponse = await getAll(date)
        if (resp.data && resp.data.events) events.value = resp.data.events
      } catch (e) {
        console.log(e)
      }
    }

    getEvents()
    return {
      shop,
      events
    }
  }
})
</script>
