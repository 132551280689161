
import { computed, defineComponent, ref } from 'vue'
import MainNav from '@/components/MainNav.vue'
import moment from 'moment-timezone'
import 'moment/locale/de'
import { useStore } from '@/use/useStore'
import { SHOP_STORE } from '@/store/constants'
import { Shop } from '@/store/modules/shop/interfaces'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'App',
  components: {
    MainNav
  },
  setup () {
    const store = useStore()
    const router = useRouter()
    const title = ref()

    const shop = computed(() => store.getters[SHOP_STORE.GETTERS.GET_SHOP_DATA])

    async function getCurrentShop () {
      try {
        if (!shop.value || !shop.value.id) {
          await store.dispatch(SHOP_STORE.ACTIONS.GET_CURRENT_SHOP)
          router.go(0)
        }
        if (shop.value && shop.value.name) {
          // document.title = shop.value.name
        }
        title.value = shop.value?.name.toUpperCase()
      } catch (e) {
        console.log('shop not found')
      }
    }
    getCurrentShop()

    moment.tz.setDefault('Europe/Berlin')
    moment.locale('de')

    function goBack () {
      router.back()
    }

    return {
      shop,
      goBack
    }
  }
})
