import { ApiResponse, getResponseData, jkasseApi } from '@/services/api/api'

/**
 * get current shop from host
 */
function getCurrentShop (): Promise<ApiResponse> {
  return jkasseApi.get('/shop/get')
    .then((response) => {
      const data = getResponseData(response)
      return Promise.resolve(data)
    })
    .catch((err) => Promise.reject(err.response))
}

export {
  getCurrentShop
}
