
import { computed, defineComponent, PropType, ref, watch } from 'vue'
import ShopEventList from '@/components/shop/event/EventList.vue'
import { useStore } from '@/use/useStore'
import { SHOP_STORE } from '@/store/constants'
import { ApiResponse } from '@/services/api/api'
import { getAll } from '@/services/api/event/api'
import { Event } from '@/store/modules/event/interfaces'
import moment from 'moment'
import { Shop } from '@/store/modules/shop/interfaces'
import { useMeta } from 'vue-meta'

export default defineComponent({
  name: 'HomeBabyboerse',
  components: { ShopEventList },
  props: {
    shop: {
      type: Object as PropType<Shop>
    }
  },
  setup (props) {
    function getDate (date: Date) {
      return moment(date).format('dddd, D. MMM YYYY')
    }
    function getTime (date: Date) {
      return moment(date).format('LT')
    }

    function getDiff (date: Date, days: number, format: string) {
      return moment(date).add(days, 'd').format(format)
    }

    function getDiffNow (start: Date) {
      const now = new Date()
      if (moment(start) < moment(now)) {
        return 'aktuell geöffnet'
      }
      return moment(start).fromNow()
    }

    const store = useStore()
    const shop = computed(() => store.getters[SHOP_STORE.GETTERS.GET_SHOP_DATA])
    const events = ref<Event[]>([])
    async function getEvents () {
      try {
        const date: Date = new Date()
        const resp: ApiResponse = await getAll(date, 'babyboerse', shop.value ? shop.value.id : undefined)
        if (resp.data && resp.data.events) events.value = resp.data.events
      } catch (e) {
        console.log(e)
      }
    }
    watch(shop, () => {
      getEvents()
    })

    getEvents()

    useMeta(computed(() => ({
      title: `Home | ${props.shop?.name.toUpperCase() || 'SHOP-VOR-ORT.DE'}`,
      meta: [
        {
          name: 'description',
          content:
            'Seit mehr als 10 Jahren findet zweimal im Jahr (Frühjahr und Herbst) die Kinderkleiderbörse, ' +
            'Spielzeugbörse und Babybörse in Windberge (Stendal) statt.' +
            'Die aktuellen Termine und wie die Anmeldung als Verkäufer funktioniert findest Du hier auf einen Blick.'
        }
      ]
    })))

    return {
      events,
      getDate,
      getTime,
      getDiff,
      getDiffNow
    }
  }
})
