import { createStore } from 'vuex'
import { IRootState } from '@/store/interfaces'
import { AuthStoreModuleTypes } from './modules/auth/types'
import { CounterStoreModuleTypes } from './modules/counter/types'
import { Counter1StoreModuleTypes } from './modules/counter1/types'
import { RootStoreModuleTypes } from './modules/root/types'
import { ShopStoreModuleTypes } from '@/store/modules/shop/types'

import root from './modules/root'

export const store = createStore<IRootState>(root)

type StoreModules = {
  authModule: AuthStoreModuleTypes
  shopModule: ShopStoreModuleTypes
  counterModule: CounterStoreModuleTypes
  counterModule1: Counter1StoreModuleTypes
  root: RootStoreModuleTypes
};

export type Store = AuthStoreModuleTypes<Pick<StoreModules, 'authModule'>> &
  ShopStoreModuleTypes<Pick<StoreModules, 'shopModule'>> &
  CounterStoreModuleTypes<Pick<StoreModules, 'counterModule'>> &
  Counter1StoreModuleTypes<Pick<StoreModules, 'counterModule1'>> &
  RootStoreModuleTypes<Pick<StoreModules, 'root'>>;
