import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/shop/Home.vue'
import { getLocalStorage } from '@/services/utils/localStorage'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/besucher',
    name: 'Besucher',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/shop/babyboerse/Besucher.vue')
  },
  {
    path: '/verkaeufer',
    name: 'Verkaeufer',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/shop/babyboerse/Verkaeufer.vue')
  },
  {
    path: '/event/:id',
    name: 'EventShow',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "event" */ '../views/shop/event/EventShow.vue')
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/login',
    name: 'AuthLogin',
    component: () =>
      import(/* webpackChunkName: "Auth" */ '../views/auth/Login.vue')
  },
  {
    path: '/register',
    name: 'AuthRegister',
    component: () =>
      import(/* webpackChunkName: "Auth" */ '../views/auth/Register.vue')
  },
  {
    path: '/user',
    name: 'UserProfile',
    component: () =>
      import(/* webpackChunkName: "User" */ '../views/user/Profile.vue')
  },
  {
    path: '/user/verify/:token',
    name: 'AuthVerify',
    component: () =>
      import(/* webpackChunkName: "Auth" */ '../views/auth/Verify.vue')
  },
  {
    path: '/user/newverify',
    name: 'AuthNewVerify',
    component: () =>
      import(/* webpackChunkName: "Auth" */ '../views/auth/NewVerify.vue')
  },
  {
    path: '/user/newPwdToken',
    name: 'AuthNewPwdToken',
    component: () =>
      import(/* webpackChunkName: "Auth" */ '../views/auth/NewPwdToken.vue')
  },
  {
    path: '/auth/changePwd/:token',
    name: 'AuthChangePwd',
    component: () =>
      import(/* webpackChunkName: "Auth" */ '../views/auth/ChangePwd.vue')
  },
  {
    path: '/cash',
    name: 'Cash',
    component: () =>
      import(/* webpackChunkName: "Cash" */ '../views/shop/cash/CashSelect.vue')
  },
  {
    path: '/cash/select',
    name: 'CashSelect',
    component: () =>
      import(/* webpackChunkName: "Cash" */ '../views/shop/cash/CashSelect.vue')
  },
  {
    path: '/cash/booking',
    name: 'CashBooking',
    component: () =>
      import(/* webpackChunkName: "Cash" */ '../views/shop/cash/CashBooking.vue')
  },
  {
    path: '/event/user',
    name: 'EventUserList',
    component: () =>
      import(/* webpackChunkName: "Seller" */ '../views/shop/event/user/EventUserList.vue')
  },
  {
    path: '/seller/bookings/:sellerId',
    name: 'SellerBookings',
    component: () =>
      import(/* webpackChunkName: "Seller" */ '../views/shop/seller/SellerBookings.vue')
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component: () =>
      import(/* webpackChunkName: "Main" */ '../views/shop/babyboerse/Impressum.vue')
  },
  {
    path: '/datenschutz',
    name: 'Datenschutz',
    component: () =>
      import(/* webpackChunkName: "Main" */ '../views/shop/babyboerse/Datenschutz.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkActiveClass: 'active',
  linkExactActiveClass: 'active',
  routes
})

router.beforeEach((to, from, next) => {
  const publicPages = [
    'AuthLogin',
    'Home',
    'EventShow',
    'Besucher',
    'Verkaeufer',
    'Impressum',
    'Datenschutz',
    'AuthRegister',
    'AuthVerify',
    'AuthNewVerify',
    'AuthNewPwdToken',
    'AuthChangePwd'
  ]
  const toName: string = to.name as string || 'Home'
  const authRequired = !publicPages.includes(toName)
  const loggedIn = getLocalStorage('currentUser')

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next({ name: 'AuthLogin', params: { from: toName } })
  } else {
    next()
  }
})

export default router
