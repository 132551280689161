import { ApiError, authApi } from '../api'
import { Login } from '@/store/modules/auth/interfaces'
import { AuthApiResponse } from '@/services/api/auth/interfaces'
import { store } from '@/store'
import router from '@/router'
import { AUTH_STORE } from '@/store/constants'
import { getLocalStorage, setLocalStorage } from '@/services/utils/localStorage'

async function authServices (): Promise<any> {
  const storedUser = await getLocalStorage('currentUser')
  if (storedUser) {
    const user = storedUser
    if (user && user.token) {
      return Promise.resolve({ Authorization: `Bearer ${user.token}` })
    }
  }
  return Promise.resolve({})
}

/**
 * user login
 * @param loginData
 */
async function authLogin (loginData: Login): Promise<AuthApiResponse> {
  return authApi.post('/login', loginData)
    .then((response) => {
      return Promise.resolve(response.data)
    })
    .catch((err) => Promise.reject(err.response))
}

/**
 * check, if a response has a new valid token
 * @param responseData
 */
function refreshedToken (responseData: any) {
  // check, if user in response
  if (responseData.user) {
    // check, if response for user has new token
    const { user } = responseData
    if (user.newToken) {
      // update new token for user
      const storedUser = getLocalStorage('currentUser')
      if (storedUser) {
        const currentUser = storedUser
        currentUser.token = user.token
        setLocalStorage('currentUser', currentUser)
      }
    }
  }
}

/**
 * check, if a response error has a forbidden response status
 * is forbidden - it checks, if token is invalid
 * @param apiError
 */
function checkTokenFromApiError (apiError: ApiError): void {
  // check for forbidden statusCode
  if (apiError.statusCode === 403) {
    // check for error in data
    if (apiError.dataErr) {
      const err: string = apiError.dataErr.toLowerCase()
      if (err.search('token')) {
        store.dispatch(AUTH_STORE.ACTIONS.LOGOUT)
        router.go(0)
        // token invalid - destroy currentUser in localStorage
        // localStorage.removeItem('currentUser')
      }
    }
  }
}

export {
  authLogin,
  authServices,
  refreshedToken,
  checkTokenFromApiError
}
