import { ApiResponse, getResponseData, jkasseApi } from '@/services/api/api'
import { Event, EventStatus } from '@/store/modules/event/interfaces'
import { authServices } from '@/services/api/auth/api'

/**
 * get event by id
 */
function getOne (id: string): Promise<Event> {
  return jkasseApi.get('/event/show/' + id)
    .then((response) => {
      const data = getResponseData(response)
      if (data.success) {
        return Promise.resolve(data.data.event)
      } else {
        return Promise.resolve(undefined)
      }
    })
    .catch((err) => Promise.reject(err.response))
}

/**
 * get one event by sellerId
 */
function getEventBySellerIdApi (sellerId: string): Promise<Event> {
  return jkasseApi.get('/event/getOneBySellerId/' + sellerId)
    .then((response) => {
      const data = getResponseData(response)
      if (data.success) {
        return Promise.resolve(data.data.event)
      } else {
        return Promise.resolve(undefined)
      }
    })
    .catch((err) => Promise.reject(err.response))
}

/**
 * get all events for current shop from host
 */
function getAll (date: Date = new Date(), category?: string, shopId?: string, status?: EventStatus, sort?: string): Promise<ApiResponse> {
  const params: any = {}
  if (date) params.date = date
  if (category) params.category = category
  if (shopId) params.shopId = shopId
  if (status) params.status = status
  if (sort) params.sort = sort

  return jkasseApi.get('/event/getAll', { params })
    .then((response) => {
      const data = getResponseData(response)
      return Promise.resolve(data)
    })
    .catch((err) => Promise.reject(err.response))
}

/**
 * get all events for current shop from host
 */
async function getAllEventsForUserApi (
  dateFrom?: Date,
  dateTo?: Date,
  seller?: boolean,
  shopId?: string,
  sort?: string
): Promise<ApiResponse> {
  const params: any = {}
  if (dateFrom) params.dateFrom = dateFrom
  if (dateTo) params.dateTo = dateTo
  if (seller) params.seller = seller
  if (shopId) params.shopId = shopId
  if (sort) params.sort = sort

  // get auth-token
  const config = {
    headers: await authServices(),
    params
  }
  return jkasseApi.get('/event/getAllForUser', config)
    .then((response) => {
      const data = getResponseData(response)
      return Promise.resolve(data)
    })
    .catch((err) => Promise.reject(err.response))
}

export {
  getOne,
  getEventBySellerIdApi,
  getAll,
  getAllEventsForUserApi
}
