
import { defineComponent, PropType } from 'vue'
import moment from 'moment'
import { Event } from '@/store/modules/event/interfaces'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'ShopEventListItem',
  props: {
    event: {
      type: Object as PropType<Event>
    }
  },
  setup (props) {
    const router = useRouter()

    function getDate (date: Date) {
      return moment(date).format('LL')
    }
    function getTime (date: Date) {
      return moment(date).format('LT')
    }

    function showEvent (id: string) {
      router.push({ name: 'EventShow', params: { id: id } })
    }

    return {
      getDate,
      getTime,
      showEvent
    }
  }
})
