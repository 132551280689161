
import { computed, defineComponent } from 'vue'
import { useStore } from '@/use/useStore'
import { AUTH_STORE } from '@/store/constants'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'MainNav',
  setup () {
    const store = useStore()
    const router = useRouter()
    const isLoggedIn = computed(() => store.getters[AUTH_STORE.GETTERS.IS_LOGGED_IN])
    const currentUser = computed(() => store.getters[AUTH_STORE.GETTERS.GET_USER_DATA])

    function logoutAction () {
      store.dispatch(AUTH_STORE.ACTIONS.LOGOUT)
      router.push({ name: 'Home' })
    }

    return {
      isLoggedIn,
      logoutAction,
      currentUser
    }
  }
})
