import { MutationTree } from 'vuex'
import { ShopMutationsTypes, ShopStateTypes, Shop } from './interfaces'
import { SHOP_STORE } from '@/store/constants'

export const mutations: MutationTree<ShopStateTypes> &
  ShopMutationsTypes = {
    [SHOP_STORE.MUTATIONS.SET_SHOP_DATA] (state: ShopStateTypes, payload: Shop) {
      if (payload) state.shop = payload
    }
  }
