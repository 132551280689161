import { Module } from 'vuex'
import { IRootState } from '@/store/interfaces'
import { ShopStateTypes } from './interfaces'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import { state } from './state'

// Module
const shop: Module<ShopStateTypes, IRootState> = {
  state,
  getters,
  mutations,
  actions
}

export default shop
