export enum RootGetters {
  UPDATE_VERSION = 'UPDATE_VERSION',
  COUNTER_CHECK = 'COUNTER_CHECK',
  SET_ROOT_DISPATCH = 'SET_ROOT_DISPATCH',
  USER_LISTS = 'USER_LISTS'
}
export enum RootMutations {
  UPDATE_VERSION = 'UPDATE_VERSION',
  COUNTER_CHECK = 'COUNTER_CHECK',
  SET_ROOT_DISPATCH = 'SET_ROOT_DISPATCH',
  USER_LISTS = 'USER_LISTS'
}
export enum RootActions {
  UPDATE_VERSION = 'UPDATE_VERSION',
  COUNTER_CHECK = 'COUNTER_CHECK',
  USER_LISTS = 'USER_LISTS'
}
export enum AuthGetters {
  GET_USER_DATA = 'GET_USER_DATA',
  IS_LOGGED_IN = 'IS_LOGGED_IN'
}

export enum AuthMutations {
  SET_USER_DATA = 'SET_USER_DATA',
  RESET_USER = 'RESET_USER',
  SET_LOGIN_ERROR = 'SET_LOGIN_ERROR'
}

export enum AuthActions {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  SIGN_UP = 'SIGN_UP',
  REFRESH_TOKEN = 'REFRESH_TOKEN'
}

export enum CounterGetters {
  COUNTER_VALUE = 'COUNTER_VALUE',
  DOUBLED_COUNTER = 'DOUBLED_COUNTER',
  GET_ROOT_DISPATCH = 'GET_ROOT_DISPATCH'
}
export enum CounterMutations {
  SET_COUNTER = 'SET_COUNTER',
  RESET_COUNTER = 'RESET_COUNTER',
  SET_ROOT_DISPATCH = 'SET_ROOT_DISPATCH'
}
export enum CounterActions {
  GET_COUNTER = 'GET_COUNTER',
  CALL_COUNTER = 'CALL_COUNTER',
  SET_ROOT_DISPATCH = 'SET_ROOT_DISPATCH'
}

export enum Counter1Getters {
  COUNTER_VALUE1 = 'COUNTER_VALUE1',
  DOUBLED_COUNTER1 = 'DOUBLED_COUNTER1',
}
export enum Counter1Mutations {
  SET_COUNTER1 = 'SET_COUNTER1',
  RESET_COUNTER1 = 'RESET_COUNTER1',
}
export enum Counter1Actions {
  GET_COUNTER1 = 'GET_COUNTER1',
  CALL_COUNTER1 = 'CALL_COUNTER1',
}

export enum ShopGetters {
  GET_SHOP_DATA = 'GET_SHOP_DATA'
}

export enum ShopMutations {
  SET_SHOP_DATA = 'SET_SHOP_DATA'
}

export enum ShopActions {
  GET_CURRENT_SHOP = 'GET_CURRENT_SHOP'
}
